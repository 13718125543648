var designTokens = require('./design-tokens');
var futureDeprecations = require('./future-deprecations');
var legacyTokens = {
  '$colour-ikea-coworker-blue': '#151e44',
  '$colour-ikea-coworker-yellow': '#ffcd3f',
  '$thickness-thick-rem': '.125rem',
  '$thickness-thin-rem': '.0625rem',
  '$body-line-height': '1.7142',
  '$body-line-height-s': '1.5',
  '$form-input-label-offset': '1.4375rem',
  '$form-input-label-size-default': '1rem',
  '$form-input-label-size-small': '.75rem',
  '$grid-max-width': '112rem',
  '$layer-1': '100',
  '$layer-2': '200',
  '$layer-3': '300',
  '$layer-4': '400'
};

function copyObject(source, target) {
  for (var i in source) {
    if (Object.prototype.hasOwnProperty.call(source, i)) {
      target[i] = source[i];
    }
  }
}
var mergedTokens = {};
copyObject(designTokens, mergedTokens);
copyObject(legacyTokens, mergedTokens);
copyObject(futureDeprecations, mergedTokens);

module.exports = mergedTokens;
