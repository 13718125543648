"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "LoadingBall", {
  enumerable: true,
  get: function () {
    return _LoadingBall.default;
  }
});
Object.defineProperty(exports, "LoadingLinear", {
  enumerable: true,
  get: function () {
    return _LoadingLinear.default;
  }
});
Object.defineProperty(exports, "default", {
  enumerable: true,
  get: function () {
    return _Loading.default;
  }
});
var _Loading = _interopRequireDefault(require("./Loading"));
var _LoadingBall = _interopRequireDefault(require("./LoadingBall"));
var _LoadingLinear = _interopRequireDefault(require("./LoadingLinear"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }