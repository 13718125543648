"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _propTypes = require("prop-types");
var _ = require("../");
const headerPropTypeValidation = (props, propName, componentName) => {
  if (props[propName] !== null && [_.ModalHeader].indexOf(props[propName]?.type) < 0) {
    return new Error('header propType only accepts ModalHeader component instance or null. If null is provided, additional CSS must be added to ensure a minimum top-padding for the content.');
  }
  return (0, _propTypes.checkPropTypes)({
    header: (0, _propTypes.oneOfType)([(0, _propTypes.oneOf)([null]).isRequired, _propTypes.node])
  }, props, 'prop', componentName);
};
var _default = headerPropTypeValidation;
exports.default = _default;