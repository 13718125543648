"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ModalBody", {
  enumerable: true,
  get: function () {
    return _elements.ModalBody;
  }
});
Object.defineProperty(exports, "ModalFooter", {
  enumerable: true,
  get: function () {
    return _elements.ModalFooter;
  }
});
Object.defineProperty(exports, "ModalHeader", {
  enumerable: true,
  get: function () {
    return _elements.ModalHeader;
  }
});
Object.defineProperty(exports, "Prompt", {
  enumerable: true,
  get: function () {
    return _variations.Prompt;
  }
});
Object.defineProperty(exports, "Sheets", {
  enumerable: true,
  get: function () {
    return _variations.Sheets;
  }
});
Object.defineProperty(exports, "Theatre", {
  enumerable: true,
  get: function () {
    return _variations.Theatre;
  }
});
exports.default = void 0;
var _elements = require("./elements");
var _variations = require("./variations");
var _Modal = _interopRequireDefault(require("./Modal"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _default = _Modal.default;
exports.default = _default;