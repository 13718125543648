"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mediaWidth = exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
var _variables = require("@ingka/variables");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
if (!_variables.breakpointM || !_variables.breakpointL || !_variables.breakpointXl) throw Error('Undefined essential design tokens! Check breakpoint references at @ingka/variables');
const mediaRules = {};
const createRules = () => {
  if (mediaRules.small) return;
  mediaRules.small = window.matchMedia(`(min-width: ${_variables.breakpointM})`);
  mediaRules.medium = window.matchMedia(`(min-width: ${_variables.breakpointL})`);
  mediaRules.large = window.matchMedia(`(min-width: ${_variables.breakpointXl})`);
  mediaRules.all = [mediaRules.small, mediaRules.medium, mediaRules.large];
};
const cleanUpOnDestroy = () => {
  mediaRules.all.length = 0;
  delete mediaRules.all;
  Object.keys(mediaRules).map(key => delete mediaRules[key]);
};
const stateSetters = [];
const getMediaWidth = () => {
  let breakpoint;
  if (mediaRules.large.matches) breakpoint = mediaWidth.XLARGE;else if (mediaRules.medium.matches) breakpoint = mediaWidth.LARGE;else if (mediaRules.small.matches) breakpoint = mediaWidth.MEDIUM;else breakpoint = mediaWidth.SMALL;
  return breakpoint;
};
const bpIndex = bp => breakpointsOrder.indexOf(bp);
const signalBreakpointChange = () => {
  let breakpoint = getMediaWidth();
  stateSetters.forEach(_ref => {
    let {
      current: {
        breakpointStateSetter,
        triggerPoints
      }
    } = _ref;
    return breakpointStateSetter(previousBreakpoint => {
      if (triggerPoints.find(triggerpoint => bpIndex(previousBreakpoint) < bpIndex(triggerpoint) !== bpIndex(breakpoint) < bpIndex(triggerpoint))) return breakpoint;
      return previousBreakpoint;
    });
  });
};
const addBreakpointListeners = () => {
  mediaRules.all.forEach(rule => mediaRules.small.addEventListener ? rule.addEventListener('change', signalBreakpointChange) : rule.addListener(signalBreakpointChange));
};
const removeBreakpointListeners = () => {
  mediaRules.all.forEach(rule => mediaRules.small.removeEventListener ? rule.removeEventListener('change', signalBreakpointChange) : rule.removeListener(signalBreakpointChange));
};
const useBreakpoint = function () {
  for (var _len = arguments.length, triggerPoints = new Array(_len), _key = 0; _key < _len; _key++) {
    triggerPoints[_key] = arguments[_key];
  }
  const [currentBreakpoint, setCurrentBreakpoint] = _react.default.useState();
  const config = _react.default.useRef();
  _react.default.useEffect(() => {
    config.current = {
      breakpointStateSetter: setCurrentBreakpoint,
      triggerPoints
    };
    if (triggerPoints.length) {
      stateSetters.push(config);
      if (stateSetters.length === 1) {
        createRules();
        addBreakpointListeners();
      }
      setCurrentBreakpoint(getMediaWidth());
    }
    return () => {
      if (stateSetters.indexOf(config) > -1) stateSetters.splice(stateSetters.indexOf(config), 1);
      if (stateSetters.length === 0) {
        removeBreakpointListeners();
        cleanUpOnDestroy();
      }
    };
  }, triggerPoints);
  return currentBreakpoint;
};
const mediaWidth = {
  SMALL: 'sm',
  MEDIUM: 'md',
  LARGE: 'lg',
  XLARGE: 'xl'
};
exports.mediaWidth = mediaWidth;
const breakpointsOrder = [mediaWidth.SMALL, mediaWidth.MEDIUM, mediaWidth.LARGE, mediaWidth.XLARGE];
var _default = useBreakpoint;
exports.default = _default;