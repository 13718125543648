"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _propTypes = require("prop-types");
var _react = _interopRequireWildcard(require("react"));
var _classnames = _interopRequireDefault(require("classnames"));
var _ssrIcon = _interopRequireDefault(require("@ingka/ssr-icon"));
var _button = _interopRequireDefault(require("@ingka/button"));
var _hyperlink = _interopRequireDefault(require("@ingka/hyperlink"));
var _crossSmall = _interopRequireDefault(require("@ingka/ssr-icon/paths/cross-small"));
var _incorrect = _interopRequireDefault(require("@ingka/ssr-icon/paths/incorrect"));
var _warningTriangle = _interopRequireDefault(require("@ingka/ssr-icon/paths/warning-triangle"));
var _checkmarkCircle = _interopRequireDefault(require("@ingka/ssr-icon/paths/checkmark-circle"));
var _informationCircle = _interopRequireDefault(require("@ingka/ssr-icon/paths/information-circle"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const icons = {
  informative: _informationCircle.default,
  positive: _checkmarkCircle.default,
  cautionary: _warningTriangle.default,
  negative: _incorrect.default
};
const InlineMessage = _ref => {
  let {
    className,
    headingLevel,
    actions,
    prefix,
    link,
    newWindow,
    linkLabel,
    body,
    ssrIcon,
    title,
    labelCloseButton,
    subtle,
    variant,
    dismissable,
    onDismissClick,
    collapseWhenClosed,
    ...other
  } = _ref;
  const wrapperRef = (0, _react.useRef)(null);
  const isInformative = variant === 'informative';
  const hideIcon = isInformative && !ssrIcon;
  const [isMessageClosed, setMessageClose] = (0, _react.useState)(false);
  const [wrapperHeight, setWrapperHeight] = (0, _react.useState)(null);
  const [collapsed, setCollapsed] = (0, _react.useState)(false);
  const closeClick = () => {
    requestAnimationFrame(function () {
      setWrapperHeight(wrapperRef.current.scrollHeight);
      requestAnimationFrame(function () {
        setWrapperHeight(0);
      });
    });
    setMessageClose(true);
  };
  const handleTransitionEnd = e => {
    if (e.propertyName !== 'height') return;
    setCollapsed(true);
    if (onDismissClick) onDismissClick(e);
  };
  const baseClass = `${prefix}inline-message`;
  const classes = (0, _classnames.default)(baseClass, `${baseClass}--${variant}`, isMessageClosed && `${baseClass}--closed`, hideIcon && `${baseClass}--icon-hidden`, subtle && `${baseClass}--subtle`, collapseWhenClosed && collapsed && `${baseClass}--collapsed`, dismissable && `${baseClass}--dismissable`, className);
  const HeadingTag = `h${headingLevel}`;
  return _react.default.createElement("div", _extends({
    "aria-hidden": isMessageClosed ? 'true' : 'false',
    className: classes,
    ref: wrapperRef,
    style: {
      height: wrapperHeight
    },
    onTransitionEnd: handleTransitionEnd
  }, other), !hideIcon && _react.default.createElement(_ssrIcon.default, {
    className: `${baseClass}__icon`,
    paths: ssrIcon ? ssrIcon : icons[variant],
    prefix: prefix
  }), _react.default.createElement("div", {
    className: `${baseClass}__content`
  }, title && !subtle && _react.default.createElement(HeadingTag, {
    className: `${baseClass}__title`
  }, title), _react.default.createElement("p", {
    className: `${baseClass}__body`
  }, body, ' ', linkLabel && _react.default.createElement(_hyperlink.default, {
    prefix: prefix,
    url: link,
    text: linkLabel,
    newWindow: newWindow
  })), !subtle && actions && _react.default.createElement("div", {
    className: `${baseClass}__actions`
  }, actions.map((action, i) => {
    return _react.default.createElement(_button.default, _extends({}, action, {
      type: "tertiary",
      small: true,
      key: i,
      prefix: prefix
    }));
  }))), dismissable && _react.default.createElement(_button.default, {
    prefix: prefix,
    type: "tertiary",
    small: true,
    xsmallIconOnly: true,
    iconOnly: true,
    ssrIcon: _crossSmall.default,
    onClick: closeClick,
    className: `${baseClass}__close-button`,
    text: labelCloseButton
  }));
};
InlineMessage.defaultProps = {
  prefix: '',
  headingLevel: '6',
  variant: 'informative'
};
var _default = InlineMessage;
exports.default = _default;