"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const getClientSize = () => ({
  w: window.innerWidth,
  h: window.innerHeight
});
const resizeListeners = [];
let debounceID;
const debouncedUpdate = () => {
  clearTimeout(debounceID);
  debounceID = setTimeout(() => {
    const clientSize = getClientSize();
    resizeListeners.forEach(setter => setter(clientSize));
  }, 40);
};
const addResizeListener = () => {
  removeResizeListener();
  window.addEventListener('resize', debouncedUpdate);
};
const removeResizeListener = () => {
  clearTimeout(debouncedUpdate);
  window.removeEventListener('resize', debouncedUpdate);
};
const useWindowSize = listen => {
  const [clientSize, setClientSize] = _react.default.useState(listen && typeof window !== 'undefined' && getClientSize());
  _react.default.useEffect(() => {
    if (listen) {
      resizeListeners.push(setClientSize);
      if (resizeListeners.length === 1) addResizeListener();
    }
    return () => {
      let index = resizeListeners.indexOf(setClientSize);
      if (index > -1) resizeListeners.splice(index, 1);
      if (resizeListeners.length === 0) removeResizeListener();
    };
  }, [listen]);
  return clientSize;
};
var _default = useWindowSize;
exports.default = _default;