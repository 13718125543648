// Do not make edits in this file!
// This file is automatically generated and any changes will be overwritten.
// To fetch new design tokens check the design-system/README.md

module.exports = {
  /**
  * 0.6875rem
  */
  fontSize25: '0.6875rem',
  /**
  * 0.75rem
  */
  fontSize50: '0.75rem',
  /**
  * 0.875rem
  */
  fontSize75: '0.875rem',
  /**
  * 1rem
  */
  fontSize100: '1rem',
  /**
  * 1.125rem
  */
  fontSize200: '1.125rem',
  /**
  * 1.25rem
  */
  fontSize300: '1.25rem',
  /**
  * 1.375rem
  */
  fontSize400: '1.375rem',
  /**
  * 1.5rem
  */
  fontSize500: '1.5rem',
  /**
  * 1.75rem
  */
  fontSize600: '1.75rem',
  /**
  * 2rem
  */
  fontSize700: '2rem',
  /**
  * 2.25rem
  */
  fontSize800: '2.25rem',
  /**
  * 2.5rem
  */
  fontSize900: '2.5rem',
  /**
  * 2.875rem
  */
  fontSize1000: '2.875rem',
  /**
  * 3.125rem
  */
  fontSize1100: '3.125rem',
  /**
  * 3.5rem
  */
  fontSize1200: '3.5rem',
  /**
  * 4rem
  */
  fontSize1300: '4rem',
  /**
  * 4.4375rem
  */
  fontSize1400: '4.4375rem',
  /**
  * 5rem
  */
  fontSize1500: '5rem',
  /**
  * 700
  */
  fontWeightBold: 700,
  /**
  * 400
  */
  fontWeightRegular: 400,
  /**
  * 1px
  */
  thicknessThin: '1px',
  /**
  * 2px
  */
  thicknessThick: '2px',
  /**
  * 0px
  */
  radiusNone: '0px',
  /**
  * 4px
  */
  radiusS: '4px',
  /**
  * 8px
  */
  radiusM: '8px',
  /**
  * 12px
  */
  radiusL: '12px',
  /**
  * 20px
  */
  radiusXl: '20px',
  /**
  * 64px
  */
  radiusCap: '64px',
  /**
  * 0.25rem
  */
  space25: '0.25rem',
  /**
  * 0.5rem
  */
  space50: '0.5rem',
  /**
  * 0.75rem
  */
  space75: '0.75rem',
  /**
  * 1rem
  */
  space100: '1rem',
  /**
  * 1.25rem
  */
  space125: '1.25rem',
  /**
  * 1.5rem
  */
  space150: '1.5rem',
  /**
  * 2rem
  */
  space200: '2rem',
  /**
  * 2.5rem
  */
  space250: '2.5rem',
  /**
  * 3rem
  */
  space300: '3rem',
  /**
  * 4rem
  */
  space400: '4rem',
  /**
  * 5.5rem
  */
  space550: '5.5rem',
  /**
  * 7.5rem
  */
  space750: '7.5rem',
  /**
  * 10rem
  */
  space1000: '10rem',
  /**
  * 13.5rem
  */
  space1350: '13.5rem',
  /**
  * 0px 4px 16px rgba(0, 0, 0, 0.1)
  */
  shadowSizeDefault: '0px 4px 16px rgba(0, 0, 0, 0.1)',
  /**
  * 800ms
  */
  durationSlowXlScreen: '800ms',
  /**
  * 400ms
  */
  durationSlowMobileNative: '400ms',
  /**
  * 250ms
  */
  durationSlowWeb: '250ms',
  /**
  * 600ms
  */
  durationStandardXlScreen: '600ms',
  /**
  * 300ms
  */
  durationStandardMobileNative: '300ms',
  /**
  * 200ms
  */
  durationStandardWeb: '200ms',
  /**
  * 300ms
  */
  durationQuickXlScreen: '300ms',
  /**
  * 150ms
  */
  durationQuickMobileNative: '150ms',
  /**
  * 100ms
  */
  durationQuickWeb: '100ms',
  /**
  * 0
  */
  durationInstantXlScreen: 0,
  /**
  * 0
  */
  durationInstantMobileNative: 0,
  /**
  * 0
  */
  durationInstantWeb: 0,
  /**
  * cubic-bezier(0,0,1,1)
  */
  easeLinear: 'cubic-bezier(0,0,1,1)',
  /**
  * cubic-bezier(0.45, 1.45, 0.65, 1.0)
  */
  easeBob: 'cubic-bezier(0.45, 1.45, 0.65, 1.0)',
  /**
  * cubic-bezier(0.9, 0.0, 1.0, 1.0)
  */
  easeExit: 'cubic-bezier(0.9, 0.0, 1.0, 1.0)',
  /**
  * cubic-bezier(0.0, 0.0, 0.1, 1.0)
  */
  easeEnter: 'cubic-bezier(0.0, 0.0, 0.1, 1.0)',
  /**
  * cubic-bezier(0.8, 0.0, 0.0, 1.0)
  */
  easeExpressive: 'cubic-bezier(0.8, 0.0, 0.0, 1.0)',
  /**
  * cubic-bezier(0.4, 0.0, 0.4, 1.0)
  */
  easeEasy: 'cubic-bezier(0.4, 0.0, 0.4, 1.0)',
  /**
  * 150ms
  */
  delayEmphasised: '150ms',
  /**
  * 100ms
  */
  delayStandard: '100ms',
  /**
  * 33ms
  */
  delayTight: '33ms',
  /**
  * 75em
  */
  breakpointXl: '75em',
  /**
  * 56.25em
  */
  breakpointL: '56.25em',
  /**
  * 37.5em
  */
  breakpointM: '37.5em',
  /**
  * 1.450
  */
  lineHeight25Short: '1.450',
  /**
  * 1.450
  */
  lineHeight25Long: '1.450',
  /**
  * 1.500
  */
  lineHeight50Short: '1.500',
  /**
  * 1.500
  */
  lineHeight50Long: '1.500',
  /**
  * 1.571
  */
  lineHeight75Short: '1.571',
  /**
  * 1.571
  */
  lineHeight75Long: '1.571',
  /**
  * 1.500
  */
  lineHeight100Short: '1.500',
  /**
  * 1.625
  */
  lineHeight100Long: '1.625',
  /**
  * 1.444
  */
  lineHeight200Short: '1.444',
  /**
  * 1.555
  */
  lineHeight200Long: '1.555',
  /**
  * 1.400
  */
  lineHeight300Short: '1.400',
  /**
  * 1.600
  */
  lineHeight300Long: '1.600',
  /**
  * 1.364
  */
  lineHeight400Short: '1.364',
  /**
  * 1.545
  */
  lineHeight400Long: '1.545',
  /**
  * 1.450
  */
  lineHeight500Short: '1.450',
  /**
  * 1.670
  */
  lineHeight500Long: '1.670',
  /**
  * 1.357
  */
  lineHeight600Short: '1.357',
  /**
  * 1.570
  */
  lineHeight600Long: '1.570',
  /**
  * 1.375
  */
  lineHeight700Short: '1.375',
  /**
  * 1.500
  */
  lineHeight700Long: '1.500',
  /**
  * 1.333
  */
  lineHeight800Short: '1.333',
  /**
  * 1.500
  */
  lineHeight800Long: '1.500',
  /**
  * 1.300
  */
  lineHeight900Short: '1.300',
  /**
  * 1.500
  */
  lineHeight900Long: '1.500',
  /**
  * 1.300
  */
  lineHeight1000Short: '1.300',
  /**
  * 1.460
  */
  lineHeight1000Long: '1.460',
  /**
  * 1.300
  */
  lineHeight1500Short: '1.300',
  /**
  * 1.500
  */
  lineHeight1500Long: '1.500',
  /**
  * 1.280
  */
  lineHeight1400Short: '1.280',
  /**
  * 1.500
  */
  lineHeight1400Long: '1.500',
  /**
  * 1.300
  */
  lineHeight1300Short: '1.300',
  /**
  * 1.500
  */
  lineHeight1300Long: '1.500',
  /**
  * 1.357
  */
  lineHeight1200Short: '1.357',
  /**
  * 1.500
  */
  lineHeight1200Long: '1.500',
  /**
  * 1.320
  */
  lineHeight1100Short: '1.320',
  /**
  * 1.520
  */
  lineHeight1100Long: '1.520',
  /**
  * 0em
  */
  letterSpacing25: '0em',
  /**
  * 0em
  */
  letterSpacing50: '0em',
  /**
  * 0em
  */
  letterSpacing75: '0em',
  /**
  * 0em
  */
  letterSpacing100: '0em',
  /**
  * 0em
  */
  letterSpacing200: '0em',
  /**
  * 0em
  */
  letterSpacing300: '0em',
  /**
  * -0.0039em
  */
  letterSpacing400: '-0.0039em',
  /**
  * -0.0042em
  */
  letterSpacing500: '-0.0042em',
  /**
  * -0.0049em
  */
  letterSpacing600: '-0.0049em',
  /**
  * -0.0056em
  */
  letterSpacing700: '-0.0056em',
  /**
  * -0.0063em
  */
  letterSpacing800: '-0.0063em',
  /**
  * -0.007em
  */
  letterSpacing900: '-0.007em',
  /**
  * -0.0078em
  */
  letterSpacing1000: '-0.0078em',
  /**
  * -0.0087em
  */
  letterSpacing1100: '-0.0087em',
  /**
  * -0.0098em
  */
  letterSpacing1200: '-0.0098em',
  /**
  * -0.011em
  */
  letterSpacing1300: '-0.011em',
  /**
  * -0.0124em
  */
  letterSpacing1400: '-0.0124em',
  /**
  * -0.014em
  */
  letterSpacing1500: '-0.014em'
};
