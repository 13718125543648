"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _propTypes = require("prop-types");
var _react = _interopRequireWildcard(require("react"));
var _ssrIcon = _interopRequireDefault(require("@ingka/ssr-icon"));
var _classnames = _interopRequireDefault(require("classnames"));
var _warningTriangle = _interopRequireDefault(require("@ingka/ssr-icon/paths/warning-triangle"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const inverseTypes = ['primary', 'emphasised', 'secondary', 'tertiary'];
const propTypes = {
  children: (0, _propTypes.oneOfType)([(0, _propTypes.arrayOf)(_propTypes.node), _propTypes.node]),
  className: _propTypes.string,
  disabled: _propTypes.bool,
  expanding: _propTypes.bool,
  fluid: _propTypes.bool,
  href: _propTypes.string,
  htmlType: _propTypes.string,
  iconOnly: _propTypes.bool,
  iconPosition: (0, _propTypes.oneOf)(['leading', 'trailing']),
  inverseTheme: (props, propName, componentName) => {
    if (inverseTypes.indexOf(props.type) === -1 && props.inverseTheme) {
      return new Error(`The property: \'inverseTheme\' provided to Button is not applicable to the Button of the type \'${props.type}\'. Please remove it or set it to false`);
    }
    return (0, _propTypes.checkPropTypes)({
      inverseTheme: _propTypes.bool
    }, props, 'prop', componentName);
  },
  loading: _propTypes.bool,
  loadingSrText: (0, _propTypes.oneOfType)([_propTypes.string, _propTypes.element]),
  newWindow: _propTypes.bool,
  onClick: _propTypes.func,
  prefix: _propTypes.string,
  small: _propTypes.bool,
  ssrIcon: _propTypes.func,
  text: (0, _propTypes.oneOfType)([_propTypes.string, _propTypes.element]),
  type: (0, _propTypes.oneOf)(['primary', 'emphasised', 'secondary', 'tertiary', 'danger', 'plain', 'image-overlay']),
  xsmallIconOnly: _propTypes.bool,
  expandedOnTouchDevice: _propTypes.bool
};
const Button = (0, _react.forwardRef)((props, ref) => {
  const {
    small,
    xsmallIconOnly,
    href,
    newWindow,
    loadingSrText,
    ssrIcon,
    iconOnly,
    prefix,
    htmlType,
    disabled,
    className,
    onClick,
    text,
    type,
    expanding,
    loading,
    iconPosition,
    fluid,
    inverseTheme,
    children,
    expandedOnTouchDevice,
    ...others
  } = props;
  function onKeyUpHandler(e) {
    if (disabled || loading || e.key !== ' ') {
      return true;
    }
    e.target.click();
  }
  let btnType = type ? type : 'secondary';
  if (iconOnly && ssrIcon) {
    btnType = `icon-${btnType}`;
  }
  if (inverseTheme && inverseTypes.indexOf(type) > -1) {
    btnType = `${btnType}-inverse`;
  }
  const innerClasses = (0, _classnames.default)(`${prefix}btn__inner`);
  let iconTag;
  const iconClass = `${prefix}btn__icon`;
  const icon = type === 'danger' ? _warningTriangle.default : ssrIcon;
  if (icon) {
    iconTag = _react.default.createElement(_ssrIcon.default, {
      className: iconClass,
      prefix: prefix,
      paths: icon
    });
  }
  const classes = (0, _classnames.default)(`${prefix}btn`, loading && `${prefix}btn--loading`, small && `${prefix}btn--small`, iconOnly && xsmallIconOnly && `${prefix}btn--xsmall`, !iconOnly && icon && iconPosition && `${prefix}${iconPosition}-icon`, type && `${prefix}btn--${btnType}`, fluid && !iconOnly && `${prefix}btn--fluid`, expanding && `${prefix}btn--expanding`, expanding && expandedOnTouchDevice && `${prefix}btn--touch-device-expanded`, className);
  const handleClick = ev => {
    if (loading || disabled) {
      ev.stopPropagation();
      return;
    }
    if (onClick && typeof onClick === 'function') onClick(ev);
  };
  const ButtonTag = href ? 'a' : 'button';
  const onKeyUp = ButtonTag === 'a' ? onKeyUpHandler : null;
  const role = href ? 'button' : null;
  const linkProps = {
    ...(!disabled && {
      href
    }),
    ...(newWindow && {
      target: '_blank'
    })
  };
  const button = _react.default.createElement(ButtonTag, _extends({
    ref: ref,
    "aria-disabled": loading,
    type: !href ? htmlType : null,
    role: role,
    disabled: href ? null : disabled,
    className: classes,
    onClick: handleClick,
    onKeyUp: onKeyUp
  }, linkProps, others), _react.default.createElement("span", {
    className: innerClasses
  }, iconTag, text && _react.default.createElement("span", {
    className: `${prefix}btn__label`
  }, text), children, loading && _react.default.createElement("span", {
    className: `${prefix}btn__loader`
  }, loadingSrText)));
  return button;
});
Button.defaultProps = {
  disabled: false,
  htmlType: 'button',
  type: 'secondary',
  prefix: '',
  iconOnly: false,
  iconPosition: 'leading',
  loadingSrText: 'Loading…'
};
Button.propTypes = propTypes;
Button.displayName = 'Button';
var _default = Button;
exports.default = _default;