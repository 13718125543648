"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireWildcard(require("react"));
var _button = _interopRequireDefault(require("@ingka/button"));
var _propTypes = require("prop-types");
var _classnames = _interopRequireDefault(require("classnames"));
var _viewportHooks = require("@ingka/viewport-hooks");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const ModalFooter = _ref => {
  let {
    prefix,
    children,
    closeBtnClick,
    renderBorder,
    className,
    ...other
  } = _ref;
  let childrenArr = _react.Children.toArray(children);
  if (childrenArr.length && childrenArr[0].type === _react.Fragment) {
    childrenArr = _react.Children.toArray(children.props.children);
  }
  const breakpoint = (0, _viewportHooks.useBreakpoint)('sm', 'md');
  const classes = (0, _classnames.default)(`${prefix}modal-footer`, !renderBorder && `${prefix}modal-footer--borderless`, childrenArr.length === 2 && `${prefix}modal-footer--dual-action`, className);
  const buttons = childrenArr.map((item, i) => {
    const clickHandlerWrapper = item.props.onClick ? ev => item.props.onClick(ev, closeBtnClick) : () => closeBtnClick();
    return _react.default.cloneElement(item, {
      prefix,
      small: breakpoint === 'sm',
      key: i,
      onClick: clickHandlerWrapper
    });
  });
  return _react.default.createElement("div", _extends({
    className: classes
  }, other), buttons);
};
ModalFooter.defaultProps = {
  prefix: '',
  renderBorder: false
};
var _default = ModalFooter;
exports.default = _default;