"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ModalBody", {
  enumerable: true,
  get: function () {
    return _ModalBody.default;
  }
});
Object.defineProperty(exports, "ModalFooter", {
  enumerable: true,
  get: function () {
    return _Footer.default;
  }
});
Object.defineProperty(exports, "ModalHeader", {
  enumerable: true,
  get: function () {
    return _Header.default;
  }
});
var _Footer = _interopRequireDefault(require("./footer/Footer"));
var _Header = _interopRequireDefault(require("./header/Header"));
var _ModalBody = _interopRequireDefault(require("./body/ModalBody"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }