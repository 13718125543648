"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
var _propTypes = require("prop-types");
var _classnames = _interopRequireDefault(require("classnames"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const ModalBody = _ref => {
  let {
    prefix,
    children,
    className,
    ...other
  } = _ref;
  return _react.default.createElement("div", _extends({}, other, {
    className: (0, _classnames.default)(`${prefix}modal-body`, className)
  }), children);
};
ModalBody.defaultProps = {
  prefix: ''
};
var _default = ModalBody;
exports.default = _default;