import openModals from './utils/ModalService';
import ShouldAutoFocus from './utils/ShouldAutoFocus';
const lockBody = (prefix) => {
    const body = document.body;
    openModals.push(true);
    if ((body.classList.toString().indexOf(`${prefix}prevent-scroll-y`) === -1)) {
        const scrollCache = window.pageYOffset || window.scrollY;
        body.setAttribute('data-skapa--scroll', scrollCache.toString());
        const paddingCache = body.style.paddingRight;
        body.setAttribute('data-skapa--pad', paddingCache);
        let scrollbarWidth = body.clientWidth;
        body.classList.add(`${prefix}prevent-scroll-y`);
        scrollbarWidth = body.clientWidth - scrollbarWidth;
        body.style.top = `-${scrollCache}px`;
        if (scrollbarWidth) {
            body.style.paddingRight = `calc(${paddingCache || '0px'} + ${scrollbarWidth}px)`;
        }
        window.scrollTo(0, 0);
    }
};
const releaseBody = (prefix) => {
    const body = document.body;
    openModals.pop();
    if (openModals.length === 0 && body.classList.toString().indexOf(`${prefix}prevent-scroll-y`) > -1) {
        const scrollCache = Number(body.getAttribute('data-skapa--scroll'));
        const paddingCache = body.getAttribute('data-skapa--pad');
        body.classList.remove(`${prefix}prevent-scroll-y`);
        body.style.paddingRight = paddingCache;
        window.scrollTo(0, scrollCache);
        body.style.top = '';
        body.removeAttribute('data-skapa--scroll');
        body.removeAttribute('data-skapa--pad');
        if (!body.style.length) {
            body.removeAttribute('style');
        }
    }
    return true;
};
const leave = (setIsOpen, setAnimationMountHelper) => {
    setIsOpen(false);
    setAnimationMountHelper(true);
};
const enter = (setIsOpen, scrollLock, prefix) => {
    setIsOpen(true);
    if (scrollLock)
        lockBody(prefix);
};
const escFunction = (e, baseClass, escapable, handleCloseBtn, setIsOpen, setAnimationMountHelper) => {
    const escapeCalledOnThisModal = !e.target.closest || e.target.closest(`.${baseClass}`) === e.currentTarget;
    if (escapeCalledOnThisModal && escapable && (e.key === 'Escape')) {
        if (handleCloseBtn) {
            handleCloseBtn(e);
        }
        leave(setIsOpen, setAnimationMountHelper);
    }
};
const onBackdropClick = (event, escapable, handleCloseBtn) => {
    if (escapable && event.target === event.currentTarget) {
        handleCloseBtn(event);
    }
};
const handleAnimationEnd = (event, animationFrameRef, isOpen, onModalOpened, onModalClosed, scrollLock, animationMountHelper, setAnimationMountHelper, prefix) => {
    cancelAnimationFrame(animationFrameRef);
    if (isOpen) {
        // Modal opening animation is finished
        if (onModalOpened)
            animationFrameRef = requestAnimationFrame(() => {
                onModalOpened(event);
            }); // animationFrame prevents multiple triggers
    }
    else if (animationMountHelper) {
        // Modal closing animation is finished
        if (scrollLock)
            releaseBody(prefix);
        setAnimationMountHelper(false);
        if (onModalClosed)
            animationFrameRef = requestAnimationFrame(() => {
                onModalClosed(event);
            }); // animationFrame prevents multiple triggers
    }
};
const onVisibleChanged = (prefix, visible, scrollLock, isOpen, setIsOpen, setAnimationMountHelper) => {
    if (visible !== isOpen) {
        if (visible) {
            enter(setIsOpen, scrollLock, prefix);
        }
        else {
            leave(setIsOpen, setAnimationMountHelper);
        }
    }
};
export { openModals, ShouldAutoFocus, leave, enter, escFunction, handleAnimationEnd, onBackdropClick, releaseBody, lockBody, onVisibleChanged };
