"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
var _propTypes = require("prop-types");
var _classnames = _interopRequireDefault(require("classnames"));
var _button = _interopRequireDefault(require("@ingka/button"));
var _cross = _interopRequireDefault(require("@ingka/ssr-icon/paths/cross"));
var _arrowLeft = _interopRequireDefault(require("@ingka/ssr-icon/paths/arrow-left"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const ModalHeader = props => {
  const {
    title,
    titleId,
    prefix,
    className,
    closeBtnClick,
    ariaCloseTxt,
    backBtnClick,
    ariaBackBtnTxt,
    floating,
    ...other
  } = props;
  const wrapperClasses = (0, _classnames.default)(`${prefix}modal-header__title-wrapper`, !backBtnClick && `${prefix}modal-header__title-wrapper--padded`);
  const headerClasses = (0, _classnames.default)(`${prefix}modal-header`, floating && `${prefix}modal-header--floating-header`, className);
  return _react.default.createElement("div", _extends({
    className: headerClasses
  }, other), _react.default.createElement(_button.default, {
    prefix: prefix,
    type: "primary",
    inverseTheme: true,
    small: true,
    iconOnly: true,
    ssrIcon: _cross.default,
    onClick: closeBtnClick,
    className: `${prefix}modal-header__close`,
    "aria-label": ariaCloseTxt
  }), !floating && title && _react.default.createElement("div", {
    className: wrapperClasses
  }, _react.default.createElement("span", {
    id: titleId,
    className: `${prefix}modal-header__title`
  }, title)), backBtnClick && _react.default.createElement(_button.default, {
    prefix: prefix,
    type: "primary",
    inverseTheme: true,
    small: true,
    iconOnly: true,
    ssrIcon: _arrowLeft.default,
    onClick: backBtnClick,
    className: `${prefix}modal-header__back-button`,
    "aria-label": ariaBackBtnTxt
  }));
};
ModalHeader.defaultProps = {
  prefix: '',
  floating: false,
  ariaBackBtnTxt: 'Back',
  ariaCloseTxt: 'Close'
};
var _default = ModalHeader;
exports.default = _default;