"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("react");
var _ShouldAutoFocus = _interopRequireDefault(require("@ingka/modal-shared/utils/ShouldAutoFocus"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const AutoFocusOnMount = () => {
  const contentRef = (0, _react.useRef)(null);
  const [wrapperFocus, setWrapperFocus] = (0, _react.useState)(undefined);
  (0, _react.useEffect)(() => {
    if (contentRef.current) {
      const wrapper = contentRef.current;
      setWrapperFocus((0, _ShouldAutoFocus.default)(wrapper));
    }
  }, []);
  return [wrapperFocus, contentRef];
};
var _default = AutoFocusOnMount;
exports.default = _default;