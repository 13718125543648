"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _propTypes = require("prop-types");
var _react = _interopRequireWildcard(require("react"));
var _classnames = _interopRequireDefault(require("classnames"));
var _ssrIcon = _interopRequireDefault(require("@ingka/ssr-icon"));
var _button = _interopRequireDefault(require("@ingka/button"));
var _magnifyingGlass = _interopRequireDefault(require("@ingka/ssr-icon/paths/magnifying-glass"));
var _crossSmall = _interopRequireDefault(require("@ingka/ssr-icon/paths/cross-small"));
var _chevronDownSmall = _interopRequireDefault(require("@ingka/ssr-icon/paths/chevron-down-small"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const Search = (0, _react.forwardRef)((props, ref) => {
  const {
    actions,
    ariaLabel,
    className,
    clearBtnText,
    submitBtnText,
    describedById,
    disabled,
    id,
    onSearch,
    onClear,
    onChange,
    onFocus,
    onBlur,
    onKeyUp,
    prefix,
    placeholder,
    scope,
    scopeSelectProps,
    ...other
  } = props;
  const {
    defaultValue,
    value
  } = other;
  const [isActive, setIsActive] = (0, _react.useState)(false);
  const [isFilled, setIsFilled] = (0, _react.useState)(Boolean(defaultValue || value));
  const inputRef = ref || (0, _react.useRef)(null);
  const selectRef = (0, _react.useRef)(null);
  const getState = () => ({
    scope: selectRef.current && selectRef.current.value,
    value: inputRef.current.value
  });
  const handleFocus = e => {
    setIsActive(true);
    if (onFocus) onFocus(e);
  };
  const handleBlur = e => {
    setIsActive(false);
    if (onBlur) onBlur(e);
  };
  const handleValueChange = e => {
    setIsFilled(Boolean(e.target.value.length));
    if (onChange) onChange(e, getState());
  };
  const handleClearValue = e => {
    inputRef.current.value = '';
    inputRef.current.focus();
    setIsFilled(false);
    if (onClear) onClear(e);
  };
  const handleSearch = e => {
    if (onSearch) onSearch(e, getState());
  };
  const handleKeyUp = e => {
    if (onKeyUp) {
      onKeyUp(e, getState());
    } else if (e.key === 'Enter') {
      handleSearch(e);
    }
  };
  const baseClass = `${prefix}search`;
  const searchClasses = (0, _classnames.default)(`${baseClass}`, isActive && `${baseClass}--active`, isFilled && `${baseClass}--filled`, disabled && `${baseClass}--disabled`, className);
  return _react.default.createElement("div", {
    className: searchClasses
  }, _react.default.createElement("label", {
    htmlFor: id,
    className: `${baseClass}__icon`
  }, _react.default.createElement(_ssrIcon.default, {
    prefix: prefix,
    paths: _magnifyingGlass.default
  })), _react.default.createElement("div", {
    className: `${baseClass}__input-wrapper`
  }, _react.default.createElement("input", _extends({
    id: id,
    ref: inputRef,
    type: "search",
    className: `${baseClass}__input`,
    disabled: disabled,
    onChange: handleValueChange,
    onFocus: handleFocus,
    onBlur: handleBlur,
    onKeyUp: handleKeyUp,
    placeholder: placeholder,
    "aria-disabled": disabled,
    "aria-describedby": describedById,
    "aria-label": ariaLabel
  }, other)), isFilled && _react.default.createElement(_button.default, {
    className: `${baseClass}__action ${baseClass}__clear`,
    prefix: prefix,
    type: "tertiary",
    small: true,
    iconOnly: true,
    ssrIcon: _crossSmall.default,
    text: clearBtnText,
    onClick: handleClearValue
  })), !isFilled && actions && actions.length > 0 && _react.default.createElement("div", {
    className: `${baseClass}__action-wrapper`
  }, actions.map((action, index) => _react.default.createElement(_button.default, {
    key: index,
    type: "tertiary",
    text: action.text,
    className: `${baseClass}__action`,
    small: true,
    iconOnly: true,
    prefix: prefix,
    ssrIcon: action.ssrIcon,
    onClick: action.onClick,
    disabled: action.disabled
  }))), scope && _react.default.createElement("div", {
    className: `${baseClass}__scope-wrapper`
  }, _react.default.createElement("span", {
    className: `${baseClass}__divider`,
    "aria-hidden": "true"
  }), _react.default.createElement("select", _extends({
    ref: selectRef,
    className: `${baseClass}__scope`
  }, scopeSelectProps), Object.keys(scope).map((scopeKey, index) => _react.default.createElement("option", {
    key: `${scopeKey}--${index}`,
    value: scope[scopeKey]
  }, scopeKey))), _react.default.createElement(_ssrIcon.default, {
    className: `${baseClass}__chevron-down`,
    prefix: prefix,
    paths: _chevronDownSmall.default
  })), isFilled && _react.default.createElement("div", {
    className: `${baseClass}__action-wrapper`
  }, _react.default.createElement("span", {
    className: `${baseClass}__divider`,
    "aria-hidden": "true"
  }), _react.default.createElement(_button.default, {
    prefix: prefix,
    htmlType: "submit",
    type: "tertiary",
    className: `${baseClass}__action`,
    small: true,
    iconOnly: true,
    ssrIcon: _magnifyingGlass.default,
    text: submitBtnText,
    onClick: handleSearch
  })));
});
Search.defaultProps = {
  prefix: '',
  placeholder: 'Search'
};
Search.displayName = 'Search';
var _default = Search;
exports.default = _default;